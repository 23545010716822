import React from 'react'
import * as SocialStyles from './Social.module.scss'
import Facebook from '../../assets/facebook.svg'
import Instagram from '../../assets/instagram.svg'
// import Twitter from '../../assets//twitter.svg'
import X from '../../assets/x.svg'
import Linkedin from '../../assets/linkedin.svg'
import Pinterest from '../../assets/pinterest.svg'
import { StaticQuery, graphql } from 'gatsby'

const Social = ({ asText }: { asText?: boolean }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          wpgraphql {
            generalSettings {
              egSettingFacebook
              egSettingInstagram
              egSettingTwitter
              egSettingLinkedin
              egSettingPinterest
            }
          }
        }
      `}
      render={data => {
        const FacebookLink =
          data.wpgraphql.generalSettings?.egSettingFacebook ?? ''
        const InstagramLink =
          data.wpgraphql.generalSettings?.egSettingInstagram ?? ''
        const TwitterLink =
          data.wpgraphql.generalSettings?.egSettingTwitter ?? ''
        const LinkedInLink = 'https://www.linkedin.com/company/muddy-stilettos/'
        const PinterestLink =
          data.wpgraphql.generalSettings?.egSettingPinterest ?? ''

        const NeedsWrapper = ({ children }: { children: any }) => {
          if (!asText)
            return <ul className={SocialStyles.Wrapper}>{children}</ul>

          return children
        }

        return (
          <NeedsWrapper>
            {FacebookLink && (
              <li>
                <a
                  href={FacebookLink}
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  {asText ? (
                    'Facebook'
                  ) : (
                    <img src={Facebook} alt={`Facebook`} />
                  )}
                </a>
              </li>
            )}
            {InstagramLink && (
              <li>
                <a
                  href={InstagramLink}
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  {asText ? (
                    'Instagram'
                  ) : (
                    <img src={Instagram} alt={`Instagram`} />
                  )}
                </a>
              </li>
            )}
            {LinkedInLink && (
              <li>
                <a
                  href={LinkedInLink}
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  {asText ? (
                    'LinkedIn'
                  ) : (
                    <img src={Linkedin} alt={`Linkedin`} />
                  )}
                </a>
              </li>
            )}
            {PinterestLink && (
              <li>
                <a
                  href={PinterestLink}
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  {asText ? (
                    'Pinterest'
                  ) : (
                    <img src={Pinterest} alt={`Pinterest`} />
                  )}
                </a>
              </li>
            )}
            {TwitterLink && (
              <li>
                <a href={TwitterLink} target="_blank" rel="nofollow noreferrer">
                  {asText ? 'X' : <img src={X} alt={`X`} />}
                </a>
              </li>
            )}
          </NeedsWrapper>
        )
      }}
    />
  )
}

export default Social

import React from 'react'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styles from './Hr.module.scss'

const Hr = () => {
  return (
    <>
      <hr />
      <hr />
    </>
  )
}

export default Hr
